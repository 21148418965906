import React, { useState } from 'react'
import PropTypes from 'prop-types'
import * as actions from 'actions'
import { connect } from 'react-redux'
import { compose } from 'recompose'
import TabBar from 'molecules/TabBar'

import { withStyles } from '@material-ui/core/styles'
import { withTranslation } from 'react-i18next'
import { LinearProgress } from '@components'
import SafeWorstTriggersContent from './SafeWorstTriggersContent'
import { useQueryParams, StringParam, withDefault } from 'use-query-params'

import moment from 'moment'

const styles = (theme) => {
	return {
		paper: {
			maxWidth: 936,
			margin: 'auto',
			overflow: 'hidden',
		},
		searchBar: {
			borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
		},
		searchInput: {
			fontSize: theme.typography.fontSize,
		},
		block: {
			display: 'block',
		},
		addUser: {
			marginRight: theme.spacing(1),
		},

		container: {
			padding: '48px 36px 0',
		},
	}
}
const tabNames = ['Safe Worst Triggers']

function AnalyticsContent(props) {
	const { classes } = props
	const [query, setQuery] = useQueryParams({
		tab: withDefault(StringParam, tabNames[0]),
	})
	const onTabChange = (newValue, tabName) => {
		setQuery({ tab: tabName })
	}

	const isLoading = false //props.analytics.loading.read
	return (
		<>
			<TabBar tabNames={tabNames} tab={query.tab} onTabChange={onTabChange} />
			<LinearProgress visible={isLoading} color="primary" />
			{
				{
					'Safe Worst Triggers': <SafeWorstTriggersContent isLoading={isLoading} />,
				}[query.tab]
			}
		</>
	)
}

AnalyticsContent.propTypes = {
	classes: PropTypes.object.isRequired,
}

const mapStateToProps = ({ }) => {
	return {
		
	}
}

export default compose(connect(mapStateToProps, actions), withStyles(styles), withTranslation())(AnalyticsContent)
