import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import * as actions from 'actions'
import { connect } from 'react-redux'
import { compose } from 'recompose'
import { withStyles } from '@material-ui/core/styles'
import AppBar from '@material-ui/core/AppBar'
import Divider from '@material-ui/core/Divider'
import Toolbar from '@material-ui/core/Toolbar'
import Button from '@material-ui/core/Button'
import Paper from '@material-ui/core/Paper'
import IconButton from '@material-ui/core/IconButton'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import Tooltip from '@material-ui/core/Tooltip'
import SearchIcon from '@material-ui/icons/Search'
import RefreshIcon from '@material-ui/icons/Refresh'
import Fuse from 'fuse.js'
import Content from '@components/Content'
import CircularProgress from '@material-ui/core/CircularProgress'

import Grid from '@material-ui/core/Grid'
import SafeWorstTriggers from './SafeWorstTriggers'

const styles = (theme) => ({
	root: {
		padding: theme.spacing(4),
	},
	paper: {
		overflow: 'hidden',
	},
	searchBar: {
		borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
	},
	roleContainer: {
		paddingTop: theme.spacing(4),
	},
	searchBarTop: {
		marginBottom: '24px',
	},
	container: {
		padding: '48px 36px',
	},
	contentWrapper: {
		marginTop: '24px',
	},
	tableWrapper: {
		padding: '0 16px',
	},
})

class AnalyticsContent extends Component {
	constructor(props) {
		super(props)
	}

	state = {
		searchVal: '',
	}

	componentDidMount = () => {
		const lastFetched = this.props.analytics.safeWorst.lastFetched
		if (!lastFetched) {
			this.hydrateData()
		} else if (lastFetched + 2 * 60 * 60 * 1000 <= new Date().getTime()) {
			this.hydrateData()
		}
	}

	hydrateData = () => {
		if (!this.props.analytics.safeWorst.loading.read) {
			this.props.fetchSafeWorst()
		}
	}

	fuse(data) {
		// const opts = {
		// 	threshold: 0,
		// 	shouldSort: true,
		// 	includeScore: true,
		// 	minMatchCharLength: 4,
		// 	keys: ['trigger', 'lag'],
		// }
		// const fuse = new Fuse(data, opts)
		// const res = this.state.searchVal
		// 	? fuse.search(this.state.searchVal).reduce((acc, res) => {
		// 			console.log(res)
		// 			acc.push(res.item)
		// 			return acc
		// 	  }, [])
		// 	: data
		// return res

		return this.state.searchVal
			? data.reduce((acc, item) => {
					if (item?.trigger?.toLowerCase()?.includes(this.state.searchVal?.toLowerCase())) {
						acc.push(item)
					}
					return acc
			  }, [])
			: data
	}

	render() {
		const { classes, analytics } = this.props
		const safestTriggerData = this.fuse(analytics.safeWorst.best)
		const worstTriggerData = this.fuse(analytics.safeWorst.worst)
		const safe = safestTriggerData
		const worst = worstTriggerData

		return [...analytics.safeWorst.best, ...analytics.safeWorst.worst].length ? (
			<div className={classes.container}>
				<Paper className={classNames(classes.paper, classes.searchBarTop)}>
					<AppBar className={classes.searchBar} position="static" color="default" elevation={0}>
						<Toolbar>
							<Grid container spacing={2} alignItems="center">
								<Grid item>
									<SearchIcon className={classes.block} color="inherit" />
								</Grid>
								<Grid item xs>
									<TextField
										fullWidth
										value={this.state.searchVal}
										onChange={(event) => {
											this.setState({ searchVal: event.target.value })
										}}
										placeholder="Search by trigger"
										InputProps={{
											disableUnderline: true,
											className: classes.searchInput,
										}}
									/>
								</Grid>

								<Grid item>
									<Tooltip arrow title="Reload" aria-label="reload">
										<IconButton color="inherit" onClick={this.hydrateData}>
											<RefreshIcon className={classes.block} color="inherit" />
										</IconButton>
									</Tooltip>
								</Grid>
							</Grid>
						</Toolbar>
					</AppBar>
					<Grid container spacing={2}>
						<Grid item sm={6} xs={12}>
							<SafeWorstTriggers
								title={'Safest Foods / Factors'}
								tooltip={
									"These are the triggers we've predicted have improved your symptoms given relative metabolic time intervals"
								}
								triggers={safe}
								defaultOrder="desc"
								colorRange={['#ccc', 'green']}
							/>
						</Grid>
						{/* <Divider orientation="vertical" flexItem absolute /> */}
						<Grid item sm={6} xs={12}>
							<SafeWorstTriggers
								title={'Worst Triggers'}
								tooltip={
									"These are the triggers we've predicted make your symptoms worse given relative metabolic time intervals"
								}
								triggers={worst}
								defaultOrder="asc"
								colorRange={['red', '#ccc']}
							/>
						</Grid>
					</Grid>
				</Paper>
				{/* <Grid container spacing={3}>
					<Grid item xs={12}>
						<Paper className={classes.contentWrapper}>
							<div>
								
							</div>
						</Paper>
					</Grid>
				</Grid> */}
			</div>
		) : analytics.safeWorst.lastFetched ? (
			<Content
				title={'Not enough logging data'}
				subtitle={'Keep logging your inputs and outputs in order to have analysis generated'}
			/>
		) : (
			<div className={classes.container}>
				<Grid container justifyContent="center">
					<CircularProgress />
				</Grid>
			</div>
		)
	}
}

AnalyticsContent.propTypes = {
	classes: PropTypes.object.isRequired,
	analytics: PropTypes.shape({
		safeWorst: PropTypes.shape({
			lastFetched: PropTypes.number,
			best: PropTypes.array,
			worst: PropTypes.array,
			loading: PropTypes.shape({
				read: PropTypes.bool.isRequired,
			}),
		}),
		loading: PropTypes.shape({
			read: PropTypes.bool.isRequired,
		}),
	}),
	fetchSafeWorst: PropTypes.func.isRequired,
}

const mapStateToProps = ({ analytics }) => {
	return {
		analytics,
	}
}

export default compose(connect(mapStateToProps, actions), withStyles(styles))(AnalyticsContent)
