import React from 'react'
import PropTypes from 'prop-types'
import * as actions from 'actions'
import { connect } from 'react-redux'
import { compose } from 'recompose'
import { withStyles } from '@material-ui/core/styles'
import AppBar from '@material-ui/core/AppBar'
import Button from '@material-ui/core/Button'

import Toolbar from '@material-ui/core/Toolbar'
import Tooltip from '@material-ui/core/Tooltip'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import RefreshIcon from '@material-ui/icons/Refresh'
import IconButton from '@material-ui/core/IconButton'

import AnalyticsContent from 'pages/AnalyticsContent'
import PrivateRoute from 'utils/PrivateRoute'
import { withTranslation } from 'react-i18next'
import { LinearProgress } from '@components'

const lightColor = 'rgba(255, 255, 255, 0.7)'

const styles = (theme) => ({
	secondaryBar: {
		zIndex: 0,
	},
	button: {
		borderColor: lightColor,
	},
})

function Analytics({ t, classes, location, pageContext, analytics }) {
	const title = t(`${pageContext.titleKey}.title`)
	return (
		<>
			<AppBar component="div" className={classes.secondaryBar} color="primary" position="static" elevation={0}>
				<Toolbar>
					<Grid container alignItems="center" spacing={2}>
						<Grid item xs>
							<Typography style={{ textTransform: 'capitalize' }} color="inherit" variant="h5">
								{title}
							</Typography>
						</Grid>
					</Grid>
					<Grid item xs />
				</Toolbar>
			</AppBar>
			<AnalyticsContent />
		</>
	)
}

Analytics.propTypes = {
	location: PropTypes.object,
	analytics: PropTypes.shape({
		loading: PropTypes.shape({
			read: PropTypes.bool.isRequired,
		}),
	}),
}

const AnalyticsPage = ({ location, ...rest }) => {
	return <PrivateRoute location={location} component={Analytics} {...rest} />
}

const mapStateToProps = ({ analytics }) => {
	return {
		analytics,
	}
}
export default compose(connect(mapStateToProps, actions), withStyles(styles), withTranslation())(AnalyticsPage)
